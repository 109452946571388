<template>
    <div>
        <!-- Search Basic Style -->
        <div class="input-group" v-if="style == 'basic'">
                        <v-text-field placeholder="Item Code, Name Barcode or Description..." v-model="collectionsStore.collectionFilters.term"
                            @update:model-value="searchTerm($event)"
                            @click:clear="clearSearch"
                            label="Item Code, Name Barcode or Description..." 
                            type="text" variant="outlined" clearable hide-details>

                            <template v-slot:append-inner >
                                    <v-progress-circular v-if="collectionsStore.loading" color="secondary" size="24"
                                        indeterminate></v-progress-circular>
                                    <div  style="cursor: pointer;">
                                        <Icon @click="openScannerDialog" name="f7:qrcode-viewfinder" size="40" style="color: var(--theme-color)"/>
                                    </div>
                            </template>
                        </v-text-field>
        </div>

        <!-- Search Basic Style -->
        <!-- <div class="input-group" v-if="style == 'berlin'">
        <a href="javascript:void(0)" class="btn" @click="toggleSearchBox()">
            <Icon name="ri:search-line"></Icon>
        </a>
    </div> -->

        <!-- Search Classic Style -->
        <!-- <div class="searchbar-box-2 input-group d-xl-flex d-none" >
        <button class="btn search-icon" type="button" @click="redirectToCollection()">
            <Icon name="ri:search-line"></Icon>
        </button>
        <input type="text" class="form-control" placeholder="Search for products, styles,brands..." v-model="term">
        <button class="btn search-button" type="button" @click="redirectToCollection()">Search</button>
    </div> -->

        <!-- Search Standard Style -->
        <!-- <div v-if="style == 'standard'">
        <input type="search" class="form-control" placeholder="search for product, delivered to your door...">
        <button class="btn search-button" @click="redirectToCollection()">
            <Icon name="ri:search-line"></Icon>
        </button>
    </div> -->

        <!-- Search Full -->
        <!-- <div class="search-full" :class="{ open: show }">
        <div class="input-group">
            <span class="input-group-text">
                <Icon name="fe:search" class="font-light"></Icon>
            </span>
            <input type="text" class="form-control search-type" placeholder="Search here..">
            <span class="input-group-text close-search" @click="toggleSearchBox()">
               <Icon name="ri-close-line"/>
            </span>
        </div>
    </div> -->
    </div>
</template>
<script setup>
import { useCollectionStore } from '~/lib/features/collections/store/collection_store';

const { $event } = useNuxtApp()
const collectionsStore = useCollectionStore()

let props = defineProps({
    style: { type: [String, Object], default: 'basic' }
})

let term = ref('');

function searchTerm(term) {
    collectionsStore.collectionFilters.term = term
    
}

function clearSearch() {
    collectionsStore.collectionFilters.term = ""
}

function openScannerDialog() {
    $event("dialog:open_scanner")
}

// let show = ref(false);
// let router = useRouter()

// function redirectToSearch() {
//     const queryParams = {};
//     if (term.value) {
//         queryParams.search = term.value;
//     }

//     router.push({
//         path: '/search',
//         query: {
//             ...queryParams,
//         },
//     });
// }

// function toggleSearchBox() {
//     show.value = !show.value
// }
</script>
